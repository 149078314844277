@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://db.onlinewebfonts.com/c/b3be20d887cc494d12ade85591c774a8?family=Sofia+Pro+Light");

@font-face {
  font-family: "Sofia Pro Light";
  src: url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.eot");
  src: url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#Sofia Pro Light")
      format("svg");
}
